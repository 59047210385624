import React, { Component } from 'react';
import HelmetEx from '../components/helmet-ex';
import ServiceRow from '../components/service-row';
import Layout from '../components/layout';
import { navigate } from 'gatsby';
import StoryOfUsImage from '../images/photos/our-story-about-us.png';

class About extends Component {

    navigateToContactPage = () => {
        navigate('/contact');
    }

    render() {
        return (
            <Layout>
                <div className="about-page">
                    <HelmetEx>
                        <div metadata="title">About Us</div>
                        <div metadata="description">
                            about us meta description :In business since 2003, Viktoria Interior
                            has been serving customers in the Bergen county area and beyond.
                        </div>
                    </HelmetEx>
                    <section className="header">
                        <div className="container">
                            <div className="content">
                                <div className="text">
                                    <div className="col-12 text-center">
                                        <h1>ABOUT US</h1>
                                        <h3 >Welcome to Viktoria Interior</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 ">
                                    <ServiceRow title="THE STORY OF US" flip src={StoryOfUsImage}>
                                        <p>
                                            In business since 2003, Viktoria Interior has been serving
                                            customers in the Bergen county area and beyond.Happy customers
                                            include celebrities, restaurants, businesses and homeowners.
                                            To see some of our work in action, visit our Virtual showroom
                                            stop by our Store
                                        </p>
                                        <button type='button' className="main-button">GET IN TOUCH</button>
                                    </ServiceRow>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Layout>
        )
    }
}

export default About; 